/* eslint-env jquery */
(function ($) {
  if ($('.testimonials-block').length) {
    $(document).ready(carouselCount);
  }

  function carouselCount() {
    $('.testimonial-holder').each(function () {
      const testimonialId = this.id;
      $(`#${testimonialId}`).on('slide.bs.carousel', (event) => {
        const nextH = $(event.relatedTarget).height();
        $(this).find('.active').parent().animate({ height: nextH }, 500);
        $(`#${testimonialId} .carousel-count .count`).text(event.to + 1);
      });
    });
  }
})(jQuery);
