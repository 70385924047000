/* eslint-env jquery */
(function ($) {
  if ($('.four-column-text-icon').length) {
    $(document).ready(fourColumnTextIconMatchHeight);
    $(window).on('resize', fourColumnTextIconMatchHeight);
  }

  function fourColumnTextIconMatchHeight() {
    $('.four-column-text-icon .content-container .pre-title').matchHeight();
    $('.four-column-text-icon .content-container h5').matchHeight();
    $('.four-column-text-icon .content-container .description').matchHeight();
  }
})(jQuery);
