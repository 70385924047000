/* eslint-env jquery */
(function ($) {
  if ($('.image-background-tile').length) {
    $(document).ready(imageBackgroundTileMatchHeight);
    $(window).on('resize', imageBackgroundTileMatchHeight);
  }

  function imageBackgroundTileMatchHeight() {
    if (window.matchMedia('(max-width: 767.98px)').matches) {
      const tileHolder = $('.image-background-tile .tile-holder');
      const tileWidth = tileHolder.width();
      $(tileHolder).each(function () {
        $(this).children('a').css('width', `${tileWidth}px`);
      });
    } else {
      $('.image-background-tile .tile-holder a').css('width', '');
    }

    $('.image-background-tile .tile-holder h3').matchHeight();
    $('.image-background-tile .tile-holder p').matchHeight();
  }
})(jQuery);
