/* eslint-env jquery */
(function ($) {
  if ($('.card-tiles').length) {
    $(document).ready(cardTilesInit);
    $(window).on('resize', cardTilesMatchHeight);
  }

  function cardTilesInit() {
    cardTiles();
    cardTilesMatchHeight();
  }

  function cardTiles() {
    const tile = $('.card-tiles .tile-holder');
    tile.each(function () {
      const front = $(this).children().children('div.front').outerHeight();
      $(this).children().children('div.back').css('height', `${front}px`);
    });
    $('.card-tiles .tile-holder .front').click(function () {
      $('.tile-holder').removeClass('active');
      $(this).parents('.tile-holder').addClass('active');
    });
    $('.card-tiles .tile-holder .back').click(function () {
      $(this).parents('.tile-holder').removeClass('active');
    });
  }

  function cardTilesMatchHeight() {
    $('.card-tiles .tile .front h4').matchHeight();
    $('.card-tiles .tile .front p').matchHeight();
  }
})(jQuery);
