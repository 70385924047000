/* eslint-env jquery */
(function ($) {

  if ($('.hero.option1').length) {
    $(document).ready(heroOverlap);
  }

  if ($('.hero.option3').length) {
    $(document).ready(heroMatchHeight);
    $(window).on('resize', heroMatchHeight);
  }

  function heroOverlap() {
    if ($('section.hero.option1').next().hasClass('three-image-text section-overlap')) {
      $('section.hero.option1 .hero-content').addClass('overlap');
    }
  }

  function heroMatchHeight() {
    if (window.matchMedia('(max-width: 575.98px)').matches) {
      const contentHeight = $('section.hero.option3 .hero-content').outerHeight();
      const imagesHeight = $('section.hero.option3 .hero-image').outerHeight();
      const totalHeight = contentHeight + imagesHeight - 90;
      $('section.hero.option3').css('height', `${totalHeight}px`);
    }
  }
})(jQuery);
