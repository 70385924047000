/* eslint-env jquery */
import bootstrap from "understrap/js/theme";

(function ($) {
  let wpadminbar = 0;
  let headerHeight;
  const header = $('header');
  $(document).ready(init);
  function init() {
    headerHeight = header.outerHeight();
    $('html').css('scroll-padding-top', `calc(${headerHeight}px + 10px)`);
    const windowHeight = window.innerHeight;
    $('.modal').appendTo('body');
    $('section:last-child[class*="bg-"]').addClass('move');
    $('main').after($('section.move'));
    if (window.matchMedia('(max-width: 991.98px)').matches) {
      $('#mega-menu-wrap-primary .mega-menu-toggle + #mega-menu-primary').css(
        'max-height',
        `calc(${windowHeight}px - ${headerHeight}px)`
      );
    }

    privacyNav();
    changeButtonSize();
    stickyNav();
    if ($('#wpadminbar').length) {
      adminSize();
    }

    select();
    anchorScroll();
  }

  $(window).on('resize', resize);

  // Menu Trigger
  $(document).ready(function($) {
    $(document).on('click', '[data-bs-toggle="offcanvas"]', function () {
      const myOffcanvas = $('#offcanvasMenu');
      const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas[0]);
      bsOffcanvas.toggle();
    });
    $(document).on('click', '[data-bs-toggle="searchForm"]', function () {
      const searchBox = $('#search-box');
      searchBox.toggleClass('expand');
    });
  });

  // Hero
  $(document).ready(function($) {
    // Sticky Navbar
    if ($('.hero').length > 0) {
      // const heroBottom = $('.hero').offset().top;
      const heroBottom = $('.hero').offset().top + $('.hero').outerHeight();
      //$('#wrapper-navbar').addClass('has-hero');

      $(window).scroll(function() {
        const scrollPos = $(window).scrollTop(); // Get the current scroll position
        if (scrollPos > heroBottom) { // Check if we've scrolled past the .hero div
          $('#wrapper-navbar').addClass('fixed');
          $('#main-nav').addClass('sticky');
        } else {
          $('#wrapper-navbar').removeClass('fixed');
          $('#main-nav').removeClass('sticky');
        }
      });
    }

    // Clipboard
    $('.clipboard').on('click', function (e){
      e.preventDefault();
      e.stopPropagation();
      const $this = $(this);

      navigator.clipboard.writeText($this.attr('href')).then(function() {
        // Create a tooltip element if it does not exist
        if ($this.children('.clipboard-tooltip').length === 0) {
          const $tooltip = $('<span class="clipboard-tooltip">Copied!</span>');
          $this.append($tooltip);
        }

        $this.children('.clipboard-tooltip').addClass('active');

        setTimeout(function() {
          $this.children('.clipboard-tooltip').removeClass('active');
        }, 2000);
      }).catch(function(error) {
        console.error('Error copying text: ', error);
      });
    });
  });

  function resize() {
    headerHeight = header.outerHeight();
    const windowHeight = window.innerHeight;
    if (window.matchMedia('(max-width: 991.98px)').matches) {
      $('#mega-menu-wrap-primary .mega-menu-toggle + #mega-menu-primary').css(
        'max-height',
        `calc(${windowHeight}px - ${headerHeight}px)`
      );
    }

    changeButtonSize();
    stickyNav();
    if ($('#wpadminbar').length) {
      adminSize();
    }
  }

  function adminSize() {
    $('#wpadminbar').prependTo('#wrapper-navbar');
    // eslint-disable-next-line no-unused-expressions
    window.matchMedia('(max-width: 782.98px)').matches ? (wpadminbar = 46) : (wpadminbar = 32);
  }

  function changeButtonSize() {
    $('[data-desktop="btn-sm"]').each(function () {
      if (window.matchMedia('(max-width: 991.98px)').matches) {
        $(this).removeClass('btn-sm');
      } else {
        $(this).addClass('btn-sm');
      }
    });
  }

  function stickyNav() {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    function insertBefore(el, referenceNode) {
      referenceNode.parentNode.insertBefore(el, referenceNode);
    }

    const checkDiv = setInterval(() => {
      const otNoticeMenu = $('.otnotice-menu');
      if (otNoticeMenu.length > 0) {
        clearInterval(checkDiv);
        if ($('body').hasClass('parent-page-privacy-policy')) {
          const navHeight = $('#main-nav').outerHeight();
          const tocHeight = $('.toc-toggler').outerHeight();
          headerHeight = wpadminbar + navHeight + tocHeight;
        } else {
          headerHeight = header.outerHeight();
        }

        if (!$('.otnotice-menu-holder').length) {
          otNoticeMenu
            .wrap('<nav class="otnotice-menu-holder"></nav>')
            .css('top', `${headerHeight}px`);
        }

        if (!$('.otnotice-content-holder').length) {
          $('.otnotice-sections').wrap('<div class="otnotice-content-holder"></div>');
        }
      }
    }, 500); // check after 100ms every time

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio === 0) {
          $('#main-nav').addClass('shrink-nav');
          if ($('body').hasClass('parent-page-privacy-policy')) {
            const navTransition = document.querySelector('#main-nav');
            navTransition.addEventListener('transitionend', () => {
              const navHeight = $('#main-nav').outerHeight();
              const tocHeight = $('.toc-toggler').outerHeight();
              headerHeight = wpadminbar + navHeight + tocHeight;
              $('.otnotice-menu').css('top', `calc(${headerHeight}px + 10px)`);
              $('html').css('scroll-padding-top', `calc(${headerHeight}px + 10px)`);
            });
          } else {
            const headerTransition = document.querySelector('header');
            headerTransition.addEventListener('transitionend', () => {
              headerHeight = header.outerHeight();
              $('.otnotice-menu').css('top', `calc(${headerHeight}px + 10px)`);
              $('html').css('scroll-padding-top', `calc(${headerHeight}px + 10px)`);
            });
          }
        } else if (entries[0].intersectionRatio === 1) {
          $('#main-nav, #pre-nav').removeClass('shrink-nav');
        }
      },
      { threshold: [0, 1] }
    );
  }

  function select() {
    $('.form-select:not(.not-form)').attr('onchange', 'jQuery(this).removeClass("empty")');
    $('.form-select option[value=""]').attr('disabled', '');

    if ($('body').hasClass('parent-page-privacy-policy')) {
      $('#otnotice-language-dropdown').change(() => {
        $('.otnotice-menu-mobile').remove();
        $('section.content .container').hide();
        setTimeout(() => {
          stickyNav();
          privacyNav();
        }, 300);
      });
    }
  }

  function anchorScroll() {
    const anchorSelector = 'a[href^="#"]';
    const anchorList = document.querySelectorAll(anchorSelector);
    for (const link of anchorList) {
      link.addEventListener('click', function (e) {
        e.preventDefault();
        if ($('body').hasClass('parent-page-privacy-policy')) {
          const navHeight = $('#main-nav').outerHeight();
          const tocHeight = $('.toc-toggler').outerHeight();
          headerHeight = wpadminbar + navHeight + tocHeight;
          $('html').css('scroll-padding-top', `calc(${headerHeight}px + 10px)`);
        } else {
          headerHeight = header.outerHeight();
          $('html').css('scroll-padding-top', `calc(${headerHeight}px + 10px)`);
        }

        const destination = document.querySelector(this.hash);
        destination.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }

  function privacyNav() {
    const checkNav = setInterval(() => {
      if ($('.otnotice-menu').length > 0) {
        clearInterval(checkNav);
        $('#otnotice-collapse-expand-icon').remove();
        $('.otnotice-menu-mobile .otnotice-menu-selected-container').wrapInner(
          '<button class="toc-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target=".otnotice-menu-mobile-container" aria-controls="otnotice-menu-mobile-container" aria-expanded="false" aria-label="Toggle Privacy Table of Contents" ></button>'
        );
        const tocButton = $('.toc-toggler');
        $('.otnotice-menu-mobile .otnotice-menu-mobile-container')
          .addClass('collapse')
          .attr('style', '');
        const otNoticeMobileContainer = $('.otnotice-menu-mobile-container');
        const otNoticeDesktopLink = $('.otnotice-menu-section a');
        otNoticeMobileContainer.replaceWith(otNoticeMobileContainer.clone());
        otNoticeDesktopLink.addClass('btn btn-link-interactive btn-sm');
        $('.otnotice-menu-section-mobile a').addClass('btn btn-link-interactive btn-md');
        $('.otnotice-menu-mobile #otnotice-menu-selected').text('Table of Contents');
        $('.otnotice-menu-mobile').appendTo('header');
        headerHeight = header.outerHeight() + 10;
        $('html').css('scroll-padding-top', `${headerHeight}px`);
        otNoticeDesktopLink.click(function (e) {
          headerHeight = header.outerHeight() + 10;
          $('html').css('scroll-padding-top', `${headerHeight}px`);
          e.preventDefault();
          const desktopDestination = document.querySelector(this.hash);
          desktopDestination.scrollIntoView({
            behavior: 'smooth'
          });
          $('.otnotice-menu-section a').removeClass('active');
          $(this).addClass('active');
        });
        $('.otnotice-menu-section-mobile a').click(function (e) {
          const navHeight = $('#main-nav').outerHeight();
          const tocHeight = $('.toc-toggler').outerHeight();
          headerHeight = wpadminbar + navHeight + tocHeight;
          // eslint-disable-next-line no-console
          console.log(`header_height${headerHeight}`);
          $('html').css('scroll-padding-top', `${headerHeight}px`);
          e.preventDefault();
          const mobileDestination = document.querySelector(this.hash);
          mobileDestination.scrollIntoView({
            behavior: 'smooth'
          });
          $('.otnotice-menu-mobile #otnotice-menu-selected').text(this.textContent);
          $('.otnotice-menu-section-mobile a').removeClass('active');
          $(this).addClass('active');
          tocButton.addClass('collapsed');
          $('.otnotice-menu-mobile-container').removeClass('show');
          $('.otnotice-menu-mobile .otnotice-menu-mobile-container').attr('style', '');
        });
        const anchors = $('.otnotice-sections').find('section');

        $(window).scroll(() => {
          let i;
          headerHeight = header.outerHeight() + 20;
          const scrollTop = $(document).scrollTop();

          // highlight the last scrolled-to: set everything inactive first
          for (i = 0; i < anchors.length; i++) {
            $(`.otnotice-menu-holder ul li a[href="#${$(anchors[i]).attr('id')}"]`).removeClass(
              'active'
            );
            $(
              `.otnotice-menu-mobile-container li a[href="#${$(anchors[i]).attr('id')}"]`
            ).removeClass('active');
          }

          // then iterate backwards, on the first match highlight it and break
          for (i = anchors.length - 1; i >= 0; i--) {
            if (scrollTop > $(anchors[i]).offset().top - headerHeight) {
              $(`.otnotice-menu-holder ul li a[href="#${$(anchors[i]).attr('id')}"]`).addClass(
                'active'
              );
              $(
                `.otnotice-menu-mobile-container li a[href="#${$(anchors[i]).attr('id')}"]`
              ).addClass('active');
              break;
            }
          }
        });
      }
    }, 700); // check after 100ms every time
    setTimeout(() => {
      $('section.content .container').show();
    }, 900);
  }

})(jQuery);
