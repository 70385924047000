/* eslint-env jquery */
(function ($) {
  $(document).ready(footerNav);
  $(window).on('resize', footerNav);
  function footerNav() {
    if (window.matchMedia('(max-width: 575.98px)').matches) {
      $('.site-footer-nav').each(function () {
        if (!$(this).hasClass('collapse-nav')) {
          $(this).addClass('collapse-nav');
        }

        const buttonCopy = $(this).children('h6').text().toLowerCase().replace(/ /g, '-');
        if (!$(this).children('h6').children('button').length) {
          $(this)
            .children('h6')
            .wrapInner(
              `<button class="collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#${buttonCopy}-collapse" aria-expanded="false" aria-controls="${buttonCopy}-collapse"></button>`
            );
        }

        if (!$(this).children(`.menu-${buttonCopy}-container`).hasClass('collapse')) {
          $(this)
            .children(`.menu-${buttonCopy}-container`)
            .addClass('collapse')
            .attr('id', `${buttonCopy}-collapse`);
        }
      });
    } else {
      $('.site-footer-nav').each(function () {
        $(this).removeClass('collapse-nav');
        const buttonCopy = $(this).children('h6').text().toLowerCase().replace(/ /g, '-');
        $(this).children('h6').children('button').contents().unwrap();
        $(this)
          .children(`.menu-${buttonCopy}-container`)
          .removeClass('collapse')
          .removeClass('show')
          .removeAttr('id');
      });
    }
  }
})(jQuery);
