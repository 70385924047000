/* eslint-env jquery */
(function ($) {
  if ($('.link-tiles').length) {
    $(document).ready(linkTilesMatchHeight);
    $(window).on('resize', linkTilesMatchHeight);
  }

  function linkTilesMatchHeight() {
    $('.link-tiles .tile a').matchHeight();
  }
})(jQuery);
