/* eslint-env jquery */
(function ($) {
  if ($('.three-image-text').length) {
    $(document).ready(threeImageTextMatchHeight);
    $(window).on('resize', threeImageTextMatchHeight);
  }

  function threeImageTextMatchHeight() {
    if (window.matchMedia('(min-width: 768px) and (max-width: 991.98px)').matches) {
      $('.three-image-text-content > div').matchHeight();
    }

    $('.three-image-text .content-container .content-row h4').matchHeight();
    $('.three-image-text .content-container .content-row p').matchHeight();
  }
})(jQuery);
