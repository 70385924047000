/* eslint-env jquery */
(function ($) {
  if ($('.three-col-row').length) {
    $(document).ready(threeColRowMatchHeight);
    $(window).on('resize', threeColRowMatchHeight);
  }

  function threeColRowMatchHeight() {
    $('.three-col-row .content-col h4').matchHeight();
    $('.three-col-row .content-col p').matchHeight();
  }
})(jQuery);
