/* eslint-env jquery */
(function ($) {
  if ($('.bio-tiles').length) {
    $(document).ready(cardTilesInit);
    $(window).on('resize', bioTilesMatchHeight);
  }

  function cardTilesInit() {
    bioTiles();
    bioTilesMatchHeight();
  }

  function bioTiles() {
    const tile = $('.bio-tile');

    tile.find('.js-expander').click(function () {
      if ($('.bio-tiles').hasClass('scrollable')) {
        const thisTile = $(this).closest('.bio-tile');
        const cardTile = $(`.description-holder.${thisTile[0].classList[1]}`);
        if (thisTile.hasClass('is-collapsed')) {
          $('.description-holder').not(cardTile).removeClass('expand').addClass('collapse');
          cardTile.removeClass('collapse').addClass('expand');
          tile.not(thisTile).removeClass('is-expanded').addClass('is-collapsed');
          thisTile.removeClass('is-collapsed').addClass('is-expanded');
        } else {
          cardTile.removeClass('expand').addClass('collapse');
          $('.description-holder').not(cardTile).removeClass('expand').addClass('collapse');
          thisTile.removeClass('is-expanded').addClass('is-collapsed');
          tile.not(thisTile).removeClass('is-inactive');
        }
      } else {
        const thisTile = $(this).closest('.bio-tile');
        if (thisTile.hasClass('is-collapsed')) {
          tile.not(thisTile).removeClass('is-expanded').addClass('is-collapsed');
          thisTile.removeClass('is-collapsed').addClass('is-expanded');
        } else {
          thisTile.removeClass('is-expanded').addClass('is-collapsed');
          tile.not(thisTile).removeClass('is-inactive');
        }
      }
    });
  }

  function bioTilesMatchHeight() {
    const ctaHolderLink = $('.cta .cta-holder a');
    $('.bio-tiles .bio-tile .details-holder').matchHeight();
    ctaHolderLink.css('left', `calc(50% - ${ctaHolderLink.outerWidth() / 2}px)`);
    $('.bio-tiles.scrollable .bio-tile').each(function () {
      const tileHeight = $(this).innerHeight();
      const tile = $(this)[0].classList[1];
      $(`.description-holder.${tile}`).css('height', `${tileHeight}px`);
    });
  }
})(jQuery);
