/* eslint-env jquery */
(function ($) {
  if ($('.cta').length) {
    $(document).ready(ctaButtonPosition);
    $(window).on('resize', ctaButtonPosition);
  }

  function ctaButtonPosition() {
    const ctaHolderLink = $('.cta .cta-holder a');
    ctaHolderLink.css('left', `calc(50% - ${ctaHolderLink.outerWidth() / 2}px)`);
  }
})(jQuery);
